<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-button type="primary" size="small" @click="dialogVisible1 = true" :disabled="!btn.add" icon="el-icon-plus">新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
        <el-button type="warning" size="small" @click="copyRow" icon="el-icon-document-copy">复制并编辑</el-button>
        <el-button type="success" size="small" @click="showSyncDialog" icon="el-icon-refresh">同步</el-button>
        <el-button type="info" size="small" icon="el-icon-refresh-right" class="vd_export" @click="buttonRefresh()">刷新</el-button>
        <el-button v-if="$refs.multiTable && $refs.multiTable.searchForm.prod_attr === 2" class="vd_export" size="small" type="danger" @click="toVoid()"
          >作废
        </el-button>
        <el-button v-if="$refs.multiTable && $refs.multiTable.searchForm.prod_attr === 2" class="vd_export" size="small" type="success" @click="exportExcel()"
          >导出Excel
        </el-button>
        <el-date-picker
          v-model="dateRange"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          end-placeholder="录入结束日期"
          range-separator="至"
          size="small"
          start-placeholder="录入开始日期"
          type="daterange"
        />
        <el-button icon="el-icon-search" size="small" type="primary" @click="getDatasNow()"> 查询</el-button>
        <div style="margin-left: 20px">
          <span>生效:{{ takeTotal }}</span>
          <span>草拟:{{ draftTotal }}</span>
        </div>
      </div>
      <div v-if="$refs.multiTable" class="flexV vg_mt_8" style="height: 36px">
        <el-radio v-model="$refs.multiTable.searchForm.prod_attr" @input="getDatasNow" :label="1">商品</el-radio>
        <el-radio v-model="$refs.multiTable.searchForm.prod_attr" @input="getDatasNow" :label="2">部件</el-radio>
        <span class="vg_mr_8" style="margin-bottom: 2px">是否套装:</span>
        <el-radio v-model="$refs.multiTable.searchForm.is_suit" @input="getDatasNow" :label="1">是</el-radio>
        <el-radio v-model="$refs.multiTable.searchForm.is_suit" @input="getDatasNow" :label="2">否</el-radio>
      </div>
      <el-row>
        <el-col :md="24">
          <search-table
            v-if="flag"
            ref="multiTable"
            :data="tableData"
            :row-class-name="({ row }) => (row.part_abolish === 1 ? 'invalidStyle' : '')"
            :tableRowKey="'prod_id'"
            :columns="tableProperties"
            v-loading="loadFlag"
            :totalPage="totalPage"
            :need-fixed-height="true"
            :need-expand="needExpand"
            :stripe="false"
            @select="selectRows"
            @selection-change="handleSelectionChange"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            @getTableData="getDatasNow"
          >
            <template v-slot:expand="scope">
              <el-card>
                <DynamicUTable
                  ref="expandTable"
                  :tableData="scope.row.prod_part_list"
                  :tableRowKey="`prod_part_id`"
                  :columns="expandTableProperties"
                  :need-pagination="false"
                  :need-search="false"
                  :need-check-box="false"
                  :autoHeight="true"
                  @row-dblclick="clickLook"
                />
              </el-card>
            </template>
            <template v-slot:status="scope">
              <div style="text-align: center">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}
                </el-tag>
              </div>
            </template>
          </search-table>
        </el-col>
      </el-row>
      <el-dialog title="请选择属性" :visible.sync="dialogVisible" width="30%">
        <el-radio v-model="radio" label="1">商品</el-radio>
        <el-radio v-model="radio" label="2">部件</el-radio>
        <el-radio v-model="radio" label="3">部件</el-radio>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="copyRowC">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="请选择类型" :visible.sync="dialogVisible1" width="30%">
        <el-radio v-model="addType" label="2">外销</el-radio>
        <el-radio v-model="addType" label="1">内贸</el-radio>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible1 = false">取 消</el-button>
          <el-button size="small" type="primary" @click="addRow">确 定</el-button>
        </span>
      </el-dialog>
      <ProdSyncDialog ref="prodSync" :syncDialogVisible="syncDialogVisible" @closeSyncDialog="closeSyncDialog" @submitSuccess="submitSuccess"></ProdSyncDialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post, postNoCatch } from '@api/request';
import { prodAPI } from '@/api/modules/prod';
import ProdSyncDialog from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdSyncDialog';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import imgPopover from '@/views/component/imgPopover';
import helper from '@assets/js/helper';
import { expandTableProperties, tableProperties } from '@/views/MessageManagement/ProdManage/prod';
import { downloadFile, getDataCenterByPermId, setTime } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';

export default {
  name: 'RequList',
  computed: {
    helper() {
      return helper;
    },
    tableRowKey() {
      let temp = this.$refs.multiTable.searchForm.prod_attr;
      if (!temp) return 'prod_id';
      return temp === 1 ? 'prod_id' : 'part_id';
    }
  },
  components: {
    SearchTable,
    DynamicUTable,
    ProdSyncDialog,
    imgPopover
  },
  data() {
    return {
      tableProperties: tableProperties,
      expandTableProperties: expandTableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      imgUpdatatime: parseInt(Math.random() * 100000000),
      dialogVisible: false,
      dialogVisible1: false,
      syncDialogVisible: false,
      radio: '1',
      addType: '2',
      draftTotal: '0',
      takeTotal: '0',
      syncData: '',
      needExpand: true,
      flag: true,
      dateRange: []
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/prod_add' || from.path === '/prod_edit') {
        this.initData();
      }
    }
  },
  filters: {},
  async mounted() {
    this.$refs.multiTable.searchForm.prod_attr = 1;
    let { prod_ids, prod_attr } = this.$route.query;
    if (prod_ids) this.$refs.multiTable.searchForm.prod_ids = prod_ids;
    if (prod_attr) this.$refs.multiTable.searchForm.prod_attr = Number(prod_attr);
    await this.initData();
  },
  methods: {
    async initData() {
      let temp = [];
      let dC10032 = await getDataCenterByPermId({ id: 10032, column: 'param5', type: 'no' });
      // dC10032.forEach(item => item.param5?.split(',').forEach(e => (e ? temp.push({ label: e, value: e }) : '')));
      dC10032.forEach(item => {
        temp.push({ label: item.param1 + (item.param2 ? '-' + item.param2 : ''), value: item.param1 + (item.param2 ? ',' + item.param2 : '') });
      });
      this.tableProperties.find(x => x.prop === 'prod_category').options = temp;
      this.getDatas();
    },
    getDataByAttr(val) {
      this.$refs.multiTable.searchForm.prod_attr = val;
      this.getDatasNow();
    },
    getDatas() {
      let searchForm = cloneDeep(this.$refs.multiTable?.searchForm);
      if (searchForm.prod_attr === 1) {
        this.tableProperties.find(x => x.prop === 'prod_partno').invisible = true;
        this.tableProperties.find(x => x.prop === 'prod_price').invisible = true;
        this.tableProperties.find(x => x.prop === 'prod_category').invisible = false;
        this.needExpand = true;
      }
      if (searchForm.prod_attr === 2) {
        this.tableProperties.find(x => x.prop === 'prod_partno').invisible = false;
        this.tableProperties.find(x => x.prop === 'prod_price').invisible = false;
        this.tableProperties.find(x => x.prop === 'prod_category').invisible = true;
        this.needExpand = false;
      }
      setTime(searchForm, 'startTime', 'endTime', this.dateRange);
      getNoCatch(prodAPI.getProds, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.takeTotal = data.takeTotal;
        this.draftTotal = data.draftTotal;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
      //供应商字段动态显示 为商品时不显示否则显示
      this.tableProperties.find(({ prop }) => prop === 'supp_abbr').invisible = searchForm.prod_attr === null || searchForm.prod_attr === 1;
    },
    // 查询方法
    getDatasNow() {
      this.loadFlag = true;
      this.getDatas();
    },
    // 刷新
    buttonRefresh() {
      this.loadFlag = true;
      this.currentPage = 1;
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getDataByAttr(1);
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDatas();
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      this.dialogVisible1 = false;
      if (!this.syncData) this.jump('/prod_add', { perm_id: this.$route.query.perm_id, addType: this.addType });
      else
        this.$router.push({
          name: 'ProdAdd',
          query: { perm_id: this.$route.query.perm_id, addType: this.addType, UUID: crypto.randomUUID() },
          params: { syncData: this.syncData }
        });
      this.syncData = '';
    },
    // 复制
    copyRow() {
      if (this.multiSelection.length !== 1) return this.$message.warning('只能选择一条');
      let { prod_id, part_id, prod_attr } = this.multiSelection[0];
      getNoCatch(prodAPI.getProdById, { prod_id: prod_id ? prod_id : part_id, prod_attr: prod_attr }).then(({ data }) => {
        if (data.form.imge_commodity_url) data.form.imge_commodity_imge_url = this.helper.picUrl(data.form.imge_commodity_url, 'm');
        if (data.form.imge_url) data.form.imge_url = this.helper.picUrl(data.form.imge_url, 'm');
        data.form.is_suit += '';
        this.$router.push({
          name: 'ProdAdd',
          query: { perm_id: this.$route.query.perm_id, addType: this.addType, prod_id: data.form.prod_id },
          params: { syncData: data.form }
        });
      });
    },
    // 作废
    toVoid() {
      if (this.multiSelection.length !== 1) return this.$message.warning('选择一条数据!');
      postNoCatch(prodAPI.edit_part_abolish, this.multiSelection[0]).then(({ data }) => {
        this.getDatas();
      });
    },
    copyRowC() {},
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      let delList = Array.from(this.multiSelection, ({ prod_id, part_id, prod_attr, is_suit }) => {
        return { prod_id: prod_id, prod_attr: prod_attr, is_suit: is_suit, part_id: part_id };
      });
      post(prodAPI.deleteProdByIds, delList)
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/prod_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.prod_attr === '2' ? row.part_id : row.prod_id,
            isCopy: row.isCopy,
            prod_no: row.prod_no,
            prod_attr: row.prod_attr,
            prod_ename: row.prod_ename,
            prod_partno: row.prod_partno,
            prod_cust_no: row.prod_cust_no
          })
        )
      });
    },
    clickLook(row) {
      let props = this.$route.query;
      this.jump('/prod_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: props.perm_id,
            form_id: row.part_id,
            prod_attr: row.prod_attr
          })
        )
      });
    },
    showSyncDialog() {
      this.$refs.prodSync.getSyncData();
      this.syncDialogVisible = true;
    },
    closeSyncDialog() {
      this.syncDialogVisible = false;
    },
    submitSuccess(data) {
      this.syncData = data;
      this.dialogVisible1 = true;
    },
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.multiTable?.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange);
      prodAPI.export_prodPart(searchForm).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    }
  }
};
</script>

<style scoped>
::v-deep .vd_picker {
  width: 95%;
  box-sizing: border-box;
}
</style>
<style>
.invalidStyle {
  background-color: #d0cece !important;
}
</style>
